import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import Button from '@mui/material/Button';

//example data type
type Logs = {
  userID: string;
  registerDate: string;
  userEmail: string;
  userAction: string;
};

//sample data
const data: Logs[] = [
  {
    userID: '#1',
    registerDate: '12.11.2023 10:40',
    userEmail: 'info@epsilos.com',
    userAction: 'Sil',
  },
  {
    userID: '#2',
    registerDate: '12.11.2023 10:40',
    userEmail: 'deniz@epsilos.com',
    userAction: 'Sil',
  },
  {
    userID: '#3',
    registerDate: '12.11.2023 10:40',
    userEmail: 'omer@epsilos.com',
    userAction: 'Sil',
  },
  {
    userID: '#4',
    registerDate: '12.11.2023 10:40',
    userEmail: 'emre@epsilos.com',
    userAction: 'Sil',
  },
  {
    userID: '#5',
    registerDate: '12.11.2023 10:40',
    userEmail: 'info@epsilos.com',
    userAction: 'Sil',
  },
];

const Errorlogs = () => {
  // Silme işlemi fonksiyonu
  const handleDelete = (userID: string) => {
    console.log(`Kullanıcı ${userID} silindi!`);
    // Burada silme işlemi gerçekleştirilebilir
  };

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<Logs>[]>(
    () => [
      {
        accessorKey: 'userID', 
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'registerDate',
        header: 'Kayıt Tarihi',
        size: 120,
      },
      {
        accessorKey: 'userEmail', 
        header: 'E-Posta',
        size: 120,
      },
      {
        accessorKey: 'userAction',
        header: 'Düzenle',
        size: 100,
        Cell: ({ cell }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDelete(cell.row.original.userID)}
          >
            Sil
          </Button>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return ( 
    <>
      <div className="section title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title d-flex justify-content-start align-items-center">
                <div className="title-icon"><i className="bi bi-person-down"></i></div>
                <div className="title-content">
                  <h1>Üyeler</h1>
                  <p>Üyeleri Filtrele</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-filter my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-block card-block-02">
                <h2 className="card-title">Üye Ara</h2>
                <div className="card-content">
                  <div className="row">
                    <MaterialReactTable table={table} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Errorlogs;
