import React, { useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { TextField, Button } from '@mui/material';

import * as XLSX from 'xlsx';


//example data type
type ApprovedApps = {
  // Temel Veriler
  AppID: string;
  userFullName: string;
  appDate: Date;
  userPhone: string;
  userEmail: string;
  appStatus: string;
  residenceCard: string;

  // Kimlik Bilgileri
  mothersName: string;
  fathersName: string;
  sex: string;
  nationality: string;
  citizenship: string;
  youthSurname: string;
  birthDate: string;
  birthPlace: string;
  city: string;
  country: string;
  address: string;
  maritalStatus: string;

  // Pasaport Bilgileri
  passportType: string;
  passportNumber: string;
  passportDate: string;
  passportEndDate: string;
  PassportCopy: string;

  // Vize Bilgileri
  visaType: string;
  visitReason: string;
  stayAddress: string;
  visaPeriodRequest: string;
  visaAddress: string;
  visaNumber: string;
  visaDate: string;

  // Ticari Vize
  businessTurkishCompanyName: string;
  businessTurkishCompanyAddress: string;
  businessAlgeriaCompanyName: string;
  businessAlgeriaCompanyAddress: string;
  businessJob: string;
  businessTitle: string;
  businessVisaDuration: string;
  businessVisaStartDate: string;
  businessCompanyInvitation: string;
  businessUndertakingLetter: string;
  businessAlgerianCompanyDocs: string;
  businessTurkishCompanyDocs: string;

  // Çalışma Vizesi
  workCompanyName: string;
  workCompanyAddress: string;
  workAlgeriaCompanyName: string;
  workAlgeriaCompanyAddress: string;
  workJob: string;
  workTitle: string;
  workVisaDuration: string;
  workVisaStartDate: string;
  workVisaDocs: string;

  // Aile Vizesi
  visaSupporterClosenessDegree: string;
  visaSupporterFullName: string;
  supporterAlgIDNumber: string;
  supporterBirthDate: string;
  supporterNationality: string;
  supporterPassportCopy: string;
  supporterIDCopy: string;
  visaStartDate: string;
  marriageCertificateCopy: string;
  civilRecords: string;
  birthCertificate: string;
  familyInvitation: string;
  kinshipCertificate: string;
  familyVisaDuration: string;

  // Turistik Vize
  roundTripTicket: string;
  travelHealthInsurance: string;
  invitationStatus: string;
  InviterIsFullName: string;
  InviterIDNumber: string;
  hotelReservation: string;
  hotelInfo: string;
  hotelReservationOrInvitationCopy: string;
  InviterIDCopy: string;
  travelHealthInsuranceCopy: string;
  roundTripTicketCopy: string;
  travelVisaDuration: string;

  // Transit Vize
  DestinationCountry: string;
  DestinationCountryVisa: string;
  DestinationCountryVisaCopy: string;



};

  // satır 2
const data: ApprovedApps[] = [
  {
    // Temel Veriler
    AppID: '2',
    userFullName: 'Deniz Gökçe',
    appDate: new Date('2024-08-23'), // ISO formatında
    userPhone: '0541484848',
    userEmail: 'deniz@epsilos.com',
    appStatus: 'Onaylandı',
    residenceCard: 'Yok',

    // Kimlik Bilgileri
    mothersName: 'Ümmühan',
    fathersName: 'Mevlüt',
    sex: 'Erkek',
    nationality: 'Türk',
    citizenship: 'TC',
    youthSurname: '-',
    birthDate: '28.08.1986',
    birthPlace: 'Çorum',
    city: 'İstanbul',
    country: 'Türkiye',
    address: 'Milönü Cd. Sancaktar İş Merkezi No:42 Kat:3',
    maritalStatus: 'Bekar',

    // Pasaport Bilgileri
    passportType: 'Kırmızı',
    passportNumber: '02797048482',
    passportDate: '10.11.2024',
    passportEndDate: '10.12.2028',
    PassportCopy: '/1.jpg',

    // Vize Bilgileri
    visaType: 'Ticari',
    visitReason: 'Ticaret',
    stayAddress: 'Sydney',
    visaPeriodRequest: '30',
    visaAddress: 'Bu vize adresimdir',
    visaNumber: '0',
    visaDate: '0',

    // Ticari Vize
    businessTurkishCompanyName: 'Smart Bilişim',
    businessTurkishCompanyAddress: 'İstanbul / Üsküdar',
    businessAlgeriaCompanyName: 'Algeria Corp',
    businessAlgeriaCompanyAddress: 'Algaria city',
    businessJob: 'Kuaför',
    businessTitle: 'Kuaför',
    businessVisaDuration: '30',
    businessVisaStartDate: '10.12.2024',
    businessCompanyInvitation: 'Yes',
    businessUndertakingLetter: 'Algeria Corp CEO',
    businessAlgerianCompanyDocs: '1',
    businessTurkishCompanyDocs: '1',

    // Çalışma Vizesi
    workCompanyName: 'Smart Bilişim',
    workCompanyAddress: 'Gülabibey Mh. Milönü Cd No:1/42 Ankara',
    workAlgeriaCompanyName: 'Algeria Work Corp.',
    workAlgeriaCompanyAddress: '767M+9H7 Rue Les Frères Ben Hammadi, Timimoun, Cezayir',
    workJob: 'Software Developer',
    workTitle: 'Senior Backend Developer',
    workVisaDuration: '28',
    workVisaStartDate: '10.12.2024',
    workVisaDocs: 'Visa Doc',

    // Aile Vizesi
    visaSupporterClosenessDegree: 'Kardeş',
    visaSupporterFullName: 'Erdem Dalkıran',
    supporterAlgIDNumber: '88048087244',
    supporterBirthDate: '10.12.1976',
    supporterNationality: 'Cezayirli',
    supporterPassportCopy: '1',
    supporterIDCopy: '1',
    visaStartDate: '10.12.2024',
    marriageCertificateCopy: '1',
    civilRecords: '1',
    birthCertificate: '1',
    familyInvitation: '1',
    kinshipCertificate: '1',
    familyVisaDuration: '30',

    // Turistik Vize
    roundTripTicket: '1',
    travelHealthInsurance: '1',
    invitationStatus: '1',
    InviterIsFullName: '1',
    InviterIDNumber: '1',
    hotelReservation: '1',
    hotelInfo: '1',
    hotelReservationOrInvitationCopy: '1',
    InviterIDCopy: '1',
    travelHealthInsuranceCopy: '1',
    roundTripTicketCopy: '1',
    travelVisaDuration: '1',

    // Transit Vize
    DestinationCountry: '1',
    DestinationCountryVisa: '1',
    DestinationCountryVisaCopy: 'Hedef Ülke Vize Kopyası',

  },
  {
    // Temel Veriler
    AppID: '1',
    userFullName: 'Ömer DALKIRAN',
    appDate: new Date('2024-08-24'), // ISO formatında
    userPhone: '05333681535',
    userEmail: 'info@epsilos.com',
    appStatus: 'Beklemede',
    residenceCard: 'Yok',

    // Kimlik Bilgileri
    mothersName: 'Ümmühan',
    fathersName: 'Mevlüt',
    sex: 'Erkek',
    nationality: 'Türk',
    citizenship: 'TC',
    youthSurname: '-',
    birthDate: '28.08.1986',
    birthPlace: 'Çorum',
    city: 'İstanbul',
    country: 'Türkiye',
    address: 'Milönü Cd. Sancaktar İş Merkezi No:42 Kat:3',
    maritalStatus: 'Bekar',

    // Pasaport Bilgileri
    passportType: 'Kırmızı',
    passportNumber: '02797048482',
    passportDate: '10.11.2024',
    passportEndDate: '10.12.2028',
    PassportCopy: '/1.jpg',

    // Vize Bilgileri
    visaType: 'Turistik',
    visitReason: 'Ticaret',
    stayAddress: 'Sydney',
    visaPeriodRequest: '30',
    visaAddress: 'Bu vize adresimdir',
    visaNumber: '0',
    visaDate: '0',

    // Ticari Vize
    businessTurkishCompanyName: 'Smart Bilişim',
    businessTurkishCompanyAddress: 'İstanbul / Üsküdar',
    businessAlgeriaCompanyName: 'Algeria Corp',
    businessAlgeriaCompanyAddress: 'Algaria city',
    businessJob: 'Kuaför',
    businessTitle: 'Kuaför',
    businessVisaDuration: '30',
    businessVisaStartDate: '10.12.2024',
    businessCompanyInvitation: 'Yes',
    businessUndertakingLetter: 'Algeria Corp CEO',
    businessAlgerianCompanyDocs: '1',
    businessTurkishCompanyDocs: '1',

    // Çalışma Vizesi
    workCompanyName: 'Smart Bilişim',
    workCompanyAddress: 'Gülabibey Mh. Milönü Cd No:1/42 Ankara',
    workAlgeriaCompanyName: 'Algeria Work Corp.',
    workAlgeriaCompanyAddress: '767M+9H7 Rue Les Frères Ben Hammadi, Timimoun, Cezayir',
    workJob: 'Software Developer',
    workTitle: 'Senior Backend Developer',
    workVisaDuration: '28',
    workVisaStartDate: '10.12.2024',
    workVisaDocs: 'Visa Doc',

    // Aile Vizesi
    visaSupporterClosenessDegree: 'Kardeş',
    visaSupporterFullName: 'Erdem Dalkıran',
    supporterAlgIDNumber: '88048087244',
    supporterBirthDate: '10.12.1976',
    supporterNationality: 'Cezayirli',
    supporterPassportCopy: '1',
    supporterIDCopy: '1',
    visaStartDate: '10.12.2024',
    marriageCertificateCopy: '1',
    civilRecords: '1',
    birthCertificate: '1',
    familyInvitation: '1',
    kinshipCertificate: '1',
    familyVisaDuration: '30',

    // Turistik Vize
    roundTripTicket: '1',
    travelHealthInsurance: '1',
    invitationStatus: '1',
    InviterIsFullName: '1',
    InviterIDNumber: '1',
    hotelReservation: '1',
    hotelInfo: '1',
    hotelReservationOrInvitationCopy: '1',
    InviterIDCopy: '1',
    travelHealthInsuranceCopy: '1',
    roundTripTicketCopy: '1',
    travelVisaDuration: '1',

    // Transit Vize
    DestinationCountry: '1',
    DestinationCountryVisa: '1',
    DestinationCountryVisaCopy: 'Hedef Ülke Vize Kopyası',

  },



];

const ApprovedApps = () => {
  // Silme işlemi fonksiyonu
  const handleDelete = (appID: string) => {
    console.log(`Kullanıcı ${appID} silindi!`);
    // Burada silme işlemi gerçekleştirilebilir
  };

  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`; // Gün/Ay/Yıl formatında
  };


  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [filteredData, setFilteredData] = useState<ApprovedApps[]>(data);



  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<ApprovedApps>[]>(
    () => [


      {
        header: 'Temel Veriler', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'AppID',
            header: 'ID',
            size: 100,
            enablePinning: true,  // Sabitlenmeyi etkinleştir
            pinned: 'left',       // Bu sütunu sola sabitle
            Cell: ({ cell }) => (
              <button className='badge text-bg-primary py-2 border-0' data-bs-toggle="modal" data-bs-target="#appStatusModal">#{cell.getValue<string>()}</button>
            ),
          },
          {
            accessorKey: 'userFullName',
            header: 'Ad Soyad',
            size: 120,
            enablePinning: true,  // Sabitlenmeyi etkinleştir
            pinned: 'left',       // Bu sütunu sola sabitle
          },
          {
            accessorKey: 'appDate',
            header: 'Tarih',
            size: 90,
            Cell: ({ cell }) => formatDate(cell.getValue<Date>()), // Formatlama

          },

          {
            accessorKey: 'userPhone',
            header: 'Telefon',
            size: 120,
          },
          {
            accessorKey: 'userEmail',
            header: 'E-Posta',
            size: 120,
          },

          {
            accessorKey: 'appStatus',
            header: 'Durum',
            size: 120,
            Cell: ({ cell }) => (
              <span className='badge text-bg-primary py-2'>{cell.getValue<string>()}</span>
            ),
          },
          {
            accessorKey: 'residenceCard',
            header: 'İkamet Kartı',
            size: 120,
          },

        ],
      },

      {
        header: 'Kimlik Bilgileri', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'mothersName',
            header: 'Anne Adı',
            size: 120,
          },
          {
            accessorKey: 'fathersName',
            header: 'Baba Adı',
            size: 120,
          },
          {
            accessorKey: 'sex',
            header: 'Cinsiyet',
            size: 120,
          },
          {
            accessorKey: 'nationality',
            header: 'Uyruk',
            size: 120,
          },
          {
            accessorKey: 'citizenship',
            header: 'Vatandaşlık',
            size: 120,
          },
          {
            accessorKey: 'youthSurname',
            header: 'Gençlik Soyadı',
            size: 120,
          },
          {
            accessorKey: 'birthDate',
            header: 'Doğum Tarihi',
            size: 120,
          },
          {
            accessorKey: 'birthPlace',
            header: 'Doğum Yeri',
            size: 120,
          },
          {
            accessorKey: 'city',
            header: 'Şehir',
            size: 120,
          },
          {
            accessorKey: 'country',
            header: 'Ülke',
            size: 120,
          },
          {
            accessorKey: 'address',
            header: 'Adres',
            size: 120,
          },

          {
            accessorKey: 'maritalStatus',
            header: 'Medeni Durum',
            size: 120,
          },
        ],
      },
      {
        header: 'Pasaport Bilgileri', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'passportType',
            header: 'Pasaport Tipi',
            size: 120,
          },
          {
            accessorKey: 'passportNumber',
            header: 'Pasaport Numarası',
            size: 120,
          },
          {
            accessorKey: 'passportDate',
            header: 'Pasaport Tarihi',
            size: 120,
          },
          {
            accessorKey: 'passportEndDate',
            header: 'Pasaport Bitiş',
            size: 120,
          },
          {
            accessorKey: 'PassportCopy',
            header: 'Pasaport Fotokopisi',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },




        ],
      },


      {
        header: 'Vize Bilgileri', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'visaType',
            header: 'Vize Tipi',
            size: 120,
          },
          {
            accessorKey: 'visitReason',
            header: 'Gidiş Nedeni',
            size: 120,
          },
          {
            accessorKey: 'stayAddress',
            header: 'Kalacağı Adres',
            size: 120,
          },
          {
            accessorKey: 'visaPeriodRequest',
            header: 'Talep Süresi',
            size: 120,
          },
          {
            accessorKey: 'visaAddress',
            header: 'Geçmiş Vize Adresi',
            size: 120,
          },
          {
            accessorKey: 'visaNumber',
            header: 'Vize Sayısı',
            size: 120,
          },
          {
            accessorKey: 'visaDate',
            header: 'Vize Tarihi',
            size: 120,
          },
        ],
      },

      {
        header: 'Ticari Vize', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'businessTurkishCompanyName',
            header: 'Şirket Adı',
            size: 120,
          },
          {
            accessorKey: 'businessTurkishCompanyAddress',
            header: 'Türk Şirket Adresi',
            size: 120,
          },

          {
            accessorKey: 'businessAlgeriaCompanyName',
            header: 'Cezayir Şirket Adı',
            size: 120,
          },
          {
            accessorKey: 'businessAlgeriaCompanyAddress',
            header: 'Cezayir Şirket Adresi',
            size: 120,
          },
          {
            accessorKey: 'businessJob',
            header: 'Meslek',
            size: 120,
          },
          {
            accessorKey: 'businessTitle',
            header: 'Ünvan',
            size: 120,
          },
          {
            accessorKey: 'businessVisaDuration',
            header: 'Vize Süresi',
            size: 120,
          },
          {
            accessorKey: 'businessVisaStartDate',
            header: 'Vize Başlangıç Tarihi',
            size: 230,
          },
          {
            accessorKey: 'businessCompanyInvitation',
            header: 'Şirket Davetiyesi',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'businessUndertakingLetter',
            header: 'Taahhütname',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'businessAlgerianCompanyDocs',
            header: 'Cezayir Şirket Evrakları',
            size: 180,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'businessTurkishCompanyDocs',
            header: 'Türk Şirket Evrakları',
            size: 180,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
        ],
      },
      {
        header: 'Çalışma Vizesi', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'workCompanyName',
            header: 'Şirket Adı',
            size: 120,
          },
          {
            accessorKey: 'workCompanyAddress',
            header: 'Şirket Adresi',
            size: 120,
          },

          {
            accessorKey: 'workAlgeriaCompanyName',
            header: 'Cezayir Şirket Adı',
            size: 120,
          },
          {
            accessorKey: 'workAlgeriaCompanyAddress',
            header: 'Cezayir Şirket Adresi',
            size: 230,
          },
          {
            accessorKey: 'workJob',
            header: 'Meslek',
            size: 120,
          },
          {
            accessorKey: 'workTitle',
            header: 'Ünvan',
            size: 120,
          },
          {
            accessorKey: 'workVisaDuration',
            header: 'Vize Süresi',
            size: 120,
          },
          {
            accessorKey: 'workVisaStartDate',
            header: 'Vize Başlangıç Tarihi',
            size: 160,
          },
          {
            accessorKey: 'workVisaDocs',
            header: 'Çalışma Vizesi Dokümanı',
            size: 160,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },


        ],
      },

      {
        header: 'Aile Vizesi', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'visaSupporterClosenessDegree',
            header: 'Dest. Yakınlık',
            size: 120,
          },
          {
            accessorKey: 'visaSupporterFullName',
            header: 'Dest. Ad Soyad',
            size: 120,
          },
          {
            accessorKey: 'supporterAlgIDNumber',
            header: 'Dest Cez Kim. No',
            size: 120,
          },
          {
            accessorKey: 'supporterBirthDate',
            header: 'Destekçi Doğum Tar.',
            size: 180,
          },
          {
            accessorKey: 'supporterNationality',
            header: 'Destekçi Uyruk',
            size: 120,
          },
          {
            accessorKey: 'familyVisaDuration',
            header: 'Vize Süresi',
            size: 120,
          },
          {
            accessorKey: 'visaStartDate',
            header: 'Vize Başlangıç Tar.',
            size: 120,
          },

          {
            accessorKey: 'marriageCertificateCopy',
            header: 'Evlilik Cüzdan Fot.',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'civilRecords',
            header: 'Vukuatlı NKÖ.',
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
            size: 120,
          },
          {
            accessorKey: 'birthCertificate',
            header: 'Çocuk Doğ. Bel.',
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
            size: 120,
          },
          {
            accessorKey: 'supporterPassportCopy',
            header: 'Dest. Pasaport Fot.',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'supporterIDCopy',
            header: 'Dest. Kimlik Fot.',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'familyInvitation',
            header: 'Davetiye',
            size: 180,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'kinshipCertificate',
            header: 'Akrabalık Belgesi',
            size: 120,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
        ],
      },
      {
        header: 'Turist Vizesi', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'roundTripTicket',
            header: 'Gidiş Dönüş Bileti',
            size: 120,
          },
          {
            accessorKey: 'travelHealthInsurance',
            header: 'Seyahat Sağlık Sigortası',
            size: 240,
          },
          {
            accessorKey: 'invitationStatus',
            header: 'Davetiye Durumu',
            size: 120,
          },
          {
            accessorKey: 'InviterIsFullName',
            header: 'Davetçi Ad Soyad',
            size: 120,
          },
          {
            accessorKey: 'InviterIDNumber',
            header: 'Davetçi Kimlik No',
            size: 120,
          },
          {
            accessorKey: 'hotelReservation',
            header: 'Otel Rezervasyonu',
            size: 120,
          },
          {
            accessorKey: 'hotelInfo',
            header: 'Otel Bilgileri',
            size: 120,
          },
          {
            accessorKey: 'hotelReservationOrInvitationCopy',
            header: 'Otel Rezervasyon veya Davetiye',
            size: 280,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },

          {
            accessorKey: 'InviterIDCopy',
            header: 'Davetçi Kimlik Fotokopisi',
            size: 280,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'travelHealthInsuranceCopy',
            header: 'Sey. Sağ. Sig. Fotokopisi',
            size: 280,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'roundTripTicketCopy',
            header: 'Gidiş Dönüş Bileti Fotokopisi',
            size: 280,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },
          {
            accessorKey: 'travelVisaDuration',
            header: 'Vize Süresi',
            size: 120,
          },
        ],
      },
      {
        header: 'Transit Vize', // Grup başlığı
        columns: [ // Alt sütunlar
          {
            accessorKey: 'DestinationCountry',
            header: 'Gidilecek Ülke',
            size: 190,
          },
          {
            accessorKey: 'DestinationCountryVisa',
            header: 'Gidilecek Ülke Vizesi',
            size: 190,
          },
          {
            accessorKey: 'DestinationCountryVisaCopy',
            header: 'Hedef Ülke Vize Fotokopisi',
            size: 260,
            Cell: ({ cell }) => (
              <a target='_blank' className='btn btn-success' href={cell.getValue<string>()}><i className="bi bi-box-arrow-up-right me-1"></i>Görüntüle</a>
            ),
          },

        ],
      },
    ],
    [],
  );


  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnPinning: true,  // Tabloda sütun sabitlemeyi etkinleştir

    initialState: {
      columnPinning: {
        left: ['AppID', 'userFullName'],
      },
      columnVisibility: {

      },
    },
  });

  // Excel Dışa Aktarma Fonksiyonu
  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ApplicationList');
    XLSX.writeFile(workbook, 'ApplicationList.xlsx');
  };

  const handleFilter = () => {
    const start = startDate ? new Date(startDate) : new Date('1900-01-01'); // Tarih yoksa eski bir tarih kullan
    const end = endDate ? new Date(endDate) : new Date(); // Tarih yoksa bugünün tarihini kullan

    const filtered = data.filter((row) => {
      const rowDate = row.appDate;
      return rowDate >= start && rowDate <= end;
    });

    setFilteredData(filtered);
  };

  return (

    <>

      <div className="section title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title d-flex justify-content-start align-items-center">
                <div className="title-icon"><i className="bi bi-journal-check"></i></div>
                <div className="title-content">
                  <h1>Onaylanan Başvurular</h1>
                  <p>Başvuruları Filtrele</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-filter my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-block card-block-02">
                <h2 className="card-title">Başvuru Ara</h2>
                <div className="card-content">
                  <div className="row">

                    <div className='d-flex'>
                      <TextField
                        type="date"
                        label="Başlangıç Tarihi"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        type="date"
                        label="Bitiş Tarihi"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        style={{ marginLeft: '1rem' }}
                      />
                      <button className='btn btn-success'
                        onClick={handleFilter}
                        style={{ marginLeft: '1rem' }}
                      >
                        Filtrele
                      </button>
                    </div>



                    <MaterialReactTable
                      columns={columns}
                      data={filteredData}
                      enableColumnPinning={true}
                      initialState={{
                        columnPinning: {
                          left: ['AppID', 'userFullName'],
                        },
                        columnVisibility: {
                          // Temel Veriler
                          AppID: true,
                          userFullName: true,
                          appDate: true,
                          userPhone: true,
                          userEmail: true,
                          appStatus: true,
                          residenceCard: false,

                          // Kimlik Bilgileri
                          mothersName: false,
                          fathersName: false,
                          sex: false,
                          nationality: false,
                          citizenship: false,
                          youthSurname: false,
                          birthDate: false,
                          birthPlace: false,
                          city: false,
                          country: false,
                          address: false,
                          maritalStatus: false,

                          // Pasaport Bilgileri
                          passportType: false,
                          passportNumber: false,
                          passportDate: false,
                          passportEndDate: false,
                          PassportCopy: false,

                          // Vize Bilgileri
                          visaType: true,
                          visitReason: false,
                          stayAddress: false,
                          visaPeriodRequest: false,
                          visaAddress: false,
                          visaNumber: false,
                          visaDate: false,

                          // Ticari Vize
                          businessTurkishCompanyName: false,
                          businessTurkishCompanyAddress: false,
                          businessAlgeriaCompanyName: false,
                          businessAlgeriaCompanyAddress: false,
                          businessJob: false,
                          businessTitle: false,
                          businessVisaDuration: false,
                          businessVisaStartDate: false,
                          businessCompanyInvitation: false,
                          businessUndertakingLetter: false,
                          businessAlgerianCompanyDocs: false,
                          businessTurkishCompanyDocs: false,

                          // Çalışma Vizesi
                          workCompanyName: false,
                          workCompanyAddress: false,
                          workAlgeriaCompanyName: false,
                          workAlgeriaCompanyAddress: false,
                          workJob: false,
                          workTitle: false,
                          workVisaDuration: false,
                          workVisaStartDate: false,
                          workVisaDocs: false,

                          // Aile Vizesi
                          visaSupporterClosenessDegree: false,
                          visaSupporterFullName: false,
                          supporterAlgIDNumber: false,
                          supporterBirthDate: false,
                          supporterNationality: false,
                          supporterPassportCopy: false,
                          supporterIDCopy: false,
                          visaStartDate: false,
                          marriageCertificateCopy: false,
                          civilRecords: false,
                          birthCertificate: false,
                          familyInvitation: false,
                          kinshipCertificate: false,
                          familyVisaDuration: false,

                          // Turistik Vize
                          roundTripTicket: false,
                          travelHealthInsurance: false,
                          invitationStatus: false,
                          InviterIsFullName: false,
                          InviterIDNumber: false,
                          hotelReservation: false,
                          hotelInfo: false,
                          hotelReservationOrInvitationCopy: false,
                          InviterIDCopy: false,
                          travelHealthInsuranceCopy: false,
                          roundTripTicketCopy: false,
                          travelVisaDuration: false,

                          // Transit Vize
                          DestinationCountry: false,
                          DestinationCountryVisa: false,
                          DestinationCountryVisaCopy: false,
                        },
                        sorting: [{ id: 'appDate', desc: true }], // Varsayılan olarak en güncel tarihe göre sıralama

                      }}
                    />
                    <div className='d-flex justify-content-start'>
                      <button className='btn btn-primary' onClick={handleExportExcel}><i className="bi bi-cloud-arrow-down-fill me-2"></i>Dışa Aktar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="modal fade app-status-modal" id="appStatusModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="appStatusModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="appStatusModalLabel">Başvuru Detayları</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <div className='table-responsive mb-4'>
                <table className="table table-striped">

                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Ad Soyad:</td>
                      <td>Ömer DALKIRAN</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Başvuru Tarihi:</td>
                      <td>23.08.2024</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Telefon:</td>
                      <td>0533 368 15 35</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>E-Posta:</td>
                      <td>info@epsilos.com</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>Durum:</td>
                      <td>Ön Başvuru Beklemede</td>
                    </tr>

                  </tbody>

                </table>
                <div className='col-12'>


                  <div className="my-3">
  <label htmlFor="statusChangeMessage" className="form-label">Mesaj</label>
  <textarea className="form-control" placeholder='Opsiyonel...' id="statusChangeMessage" rows={4}></textarea>
</div>

<label htmlFor="changeStatus w-100" className="form-label">Durum Değiştir</label>
<select className="form-select" aria-label="Default select changeStatus">
                    <option value="1">Ön Başvuru Onaylandı</option>
                    <option value="2">Vize Onaylandı</option>
                    <option value="3">Vize Reddedildi</option>
                  </select>

<button type="button" className="btn btn-primary mt-4 w-100">Durum Değiştir</button>
                </div>

                
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
            
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ApprovedApps;
