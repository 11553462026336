import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

//example data type
type Logs = {
  logID: string;
  logDate: string;
  logDescription: string;

};

//nested data is ok, see accessorKeys in ColumnDef below
const data: Logs[] = [
  {
    logID: '#1',
    logDate: '12.11.2023 10:40',
    logDescription: 'logID: string; logDescription: string; topicStarter: string; topicStatus: string; topicPostCount: string; topicRating: string; topicAction: string;',

  },
  {
    logID: '#2',
    logDate: '12.11.2023 10:40',
    logDescription: 'logID: string; logDescription: string; topicStarter: string; topicStatus: string; topicPostCount: string; topicRating: string; topicAction: string;',

  },
  {
    logID: '#3',
    logDate: '12.11.2023 10:40',
    logDescription: 'logID: string; logDescription: string; topicStarter: string; topicStatus: string; topicPostCount: string; topicRating: string; topicAction: string;',

  },
  {
    logID: '#4',
    logDate: '12.11.2023 10:40',
    logDescription: 'logID: string; logDescription: string; topicStarter: string; topicStatus: string; topicPostCount: string; topicRating: string; topicAction: string;',

  },
  {
    logID: '#5',
    logDate: '12.11.2023 10:40',
    logDescription: 'logID: string; logDescription: string; topicStarter: string; topicStatus: string; topicPostCount: string; topicRating: string; topicAction: string;',

  },
];

const Errorlogs = () => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<Logs>[]>(
    () => [
      {
        accessorKey: 'logID', 
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'logDate',
        header: 'Tarih',
        size: 120,
      },
      {
        accessorKey: 'logDescription', 
        header: 'Açıklama',
        size: 360,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return ( 
    <>
              <div className="section title-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title d-flex justify-content-start align-items-center">
                                <div className="title-icon"><i className="bi bi-journal-text"></i></div>
                                <div className="title-content">
                                    <h1>Loglar</h1>
                                    <p>Sistem Loglarını İnceleyin</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="section section-filter my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-block card-block-02">
                                <h2 className="card-title">Kayıtları Filtrele</h2>
                                <div className="card-content">
                                    <div className="row">

                                    <MaterialReactTable table={table} />
                                    <button className='btn btn-primary mt-4'>Tümünü Temizle</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

  </>
  )
};

export default Errorlogs;
