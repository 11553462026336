import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox, Container,
    FormControlLabel,
    FormGroup,
    Link,
    Stack,
    TextField, Typography
} from "@mui/material";
import React, {FC, useState} from "react";
import {ToastContainer} from "react-toastify";
import {AuthenticationService} from "../../services/AuthenticationService";
import {useSessionStore} from "../../stores/sessionStore";
import {useNavigate} from "react-router-dom";

interface LoginProps {

}

const Login: FC<LoginProps> = ({}: LoginProps) => {

    const authenticationService = new AuthenticationService()
    const sessionStore = useSessionStore();
    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    return (
        <Container fixed>
            <ToastContainer/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Card sx={{minWidth: 450, mx: "auto"}} variant={"elevation"}>
                    <CardContent>
                        <Stack direction={"column"} spacing={3} px={5} py={2}>
<h2 className="mb-3">Giriş Yap</h2>
                            <TextField
                                label={'Kullanıcı Adı'}
                                variant="outlined"
                                value={username}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUsername(event.target.value);
                                }}
                            />
                            <TextField
                                label={'Şifre'}
                                type={"password"}
                                variant="outlined"
                                value={password}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // onFocus={() => setDisplayAlert(false)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPassword(event.target.value);
                                }}
                            />

                        </Stack>
                        <Stack direction={"column"} px={5}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            // checked={remember}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                // setRemember(event.target.checked);
                                            }}
                                        />
                                    }
                                    label={'Şifremi Hatırla'}
                                />
                            </FormGroup>
                            {/* <Link
                                style={{cursor: "pointer"}}
                                // onClick={() => router.push("forgotPassword")}
                            >
                                {'Forgot Password'}
                            </Link>*/}

                            <Box>
                                <Typography color={'error'}>{error}</Typography>
                            </Box>
                            <Box sx={{textAlign: "right"}}>
                                <Button
                                    variant={"contained"}
                                    sx={{alignSelf: "right"}}
                                    onClick={() => {
                                        authenticationService.login(username.trim(), password.trim())
                                            .then(response => {
                                                if (response.access_token) {
                                                    sessionStore.login(response);
                                                    setTimeout(() => {
                                                        navigate('/')
                                                    }, 100)
                                                }
                                                if (response.error) {
                                                    setError(response.error)
                                                }
                                                console.log(response)
                                            })
                                    }}
                                >
                                    GİRİŞ
                                </Button>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
};

export default Login;
