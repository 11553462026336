import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png';

interface HeaderProps {
    onToggle: () => void;
}

const Header: FC<HeaderProps> = ({ onToggle }) => {
    return (
        <>
            <header>
                <div className="header">
                    <div className="container-fluid">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 offset-lg-2 search-column">
                                <div className="d-flex justify-content-start align-items-center"></div>
                            </div>
                            <div className="col-lg-4">
                                <div className="header-right d-flex justify-content-end">
                                    <button
                                        className="icon-button sidebar-control-button"
                                        type="button"
                                        onClick={onToggle}
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#sideBar"
                                        aria-controls="sideBar"
                                    >
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>

                                    <button className="icon-button setting-button">
                                        <i className="fa-regular fa-envelope"></i>
                                    </button>

                                    <a className="icon-button setting-button" href="#!">
                                        <i className="bi bi-person"></i>
                                    </a>

                                    <NavLink to="/settings" className="icon-button setting-button">
                                        <i className="bi bi-gear"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-mobile">
                    <div className="container-fluid">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 col-5 offset-lg-2 search-column">
                                <div className="d-flex justify-content-start align-items-center">
                                    <a href="/" title="Be Partners Logo">
                                        <img src={logo} className="img-fluid" alt="logo"></img>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-7">
                                <div className="header-right d-flex justify-content-end">
                                    <button
                                        className="icon-button sidebar-control-button"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#sideBarMobile"
                                        aria-controls="sideBarMobileLabel"
                                    >
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>

                                    <button className="icon-button setting-button">
                                        <i className="fa-regular fa-envelope"></i>
                                    </button>

                                    <a className="icon-button setting-button" href="#!">
                                        <i className="bi bi-person"></i>
                                    </a>

                                    <NavLink to="/settings" className="icon-button setting-button">
                                        <i className="bi bi-gear"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
