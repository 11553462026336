import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LeftDrawer from '../LeftDrawer/LeftDrawer';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css';
import { ToastContainer } from 'react-toastify';

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
    const [isToggled, setIsToggled] = useState(true); // Varsayılan olarak true

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    return (
        <>
            <ToastContainer />
            <Header onToggle={handleToggle} />
            <LeftDrawer />
            <section className={`wrapper ${isToggled ? 'toggle' : ''}`}>
                <Outlet />
            </section>
        </>
    );
};

export default Layout;
