import React from 'react'

export default function Settings() {
    return (
        <>
            <div className="section title-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title d-flex justify-content-start align-items-center">
                                <div className="title-icon"><i className="bi bi-sliders"></i></div>
                                <div className="title-content">
                                    <h1>Ayarlar</h1>
                                    <p>Ayarları Yönet</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="section section-analytics my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-block card-block-02">
                                <h2 className="card-title">Analytics Ayarları</h2>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <label htmlFor="g4Tag" className="form-label">G4 Etiketi</label>
                                            <textarea className="form-control" id="g4Tag" rows={4}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-analytics my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-block card-block-02">
                                <h2 className="card-title">İletişim Ayarları</h2>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <label htmlFor="phone" className="form-label">Telefon Numarası</label>
                                            <input type='text' className="form-control" id="phone"></input>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label htmlFor="whatsapp" className="form-label">Whatsapp Destek Hattı</label>
                                            <input type='text' className="form-control" id="whatsapp"></input>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label htmlFor="address" className="form-label">Adres</label>
                                            <input type='text' className="form-control" id="address"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
